import React from 'react';
import { Link, graphql, StaticQuery } from "gatsby";
import * as Icon from 'react-feather';

// import Logo from '../../assets/getwidget/logo.png'
// import MapImg from '../../assets/images/map.png'
// import Shape1 from '../../assets/images/shape1.png'
// import Shape2 from '../../assets/images/shape2.svg'
const GET_CATEGORIES = graphql`
query GetCategories {
    allStrapiCategories {
      nodes {
        category_slug
        category_name
      }
    }
  }
`;
const currentYear = new Date().getFullYear();
const Footer = () => (
    <StaticQuery query={GET_CATEGORIES} render={categoryData => {
        return (
            <footer className="footer-area bg-f7fafd">
                <div className="container-fluid padd-3">
                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-sm-6">
                            <div className="single-footer-widget">
                                <div className="logo">
                                    <Link>
                                        <img src="https://ik.imagekit.io/ionicfirebaseapp/getwidget/gatsby-marketplace/logo_8AkrwimDP.png?updatedAt=1640329266434" loading="lazy" alt="logo" />
                                    </Link>
                                </div>
                                <p>Browse your Flutter Business app at GetWidget Flutter Market Place.</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="single-footer-widget pl-5">
                                <h3>Company</h3>
                                <ul className="list">
                                    <li>
                                        <Link to="/about-us/">
                                            About Us
                                        </Link>
                                    </li>
                                    <li>
                                        <a href="https://www.getwidget.dev/hire-flutter-developer"target="_blank"> Hire flutter developer</a> 
                                    </li>
                                    <li>
                                        <Link to="/contact/">
                                            Contact
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/licence/">
                                            License
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/privacy-policy/">
                                            Privacy Policy
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/refund-policy/">
                                            Refund Policy
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/terms-conditions/">
                                            Terms & Conditions
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link to="">
                                            FAQs
                                        </Link>
                                    </li> */}
                                    {/* <li>
                                    <Link to="/services-1">
                                        Services
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/features">
                                        Features
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/pricing">
                                        Our Pricing
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/blog-1">
                                        Latest News
                                    </Link>
                                </li> */}
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="single-footer-widget">
                                <h3>Categories</h3>
                                <ul className="list">
                                    {categoryData.allStrapiCategories.nodes.map((category, c) => (
                                        <li key={c}>
                                            <Link to={`/category/${category.category_slug}/`}>
                                                {category.category_name}
                                            </Link>
                                        </li>
                                    ))}
                                    {/* <li>
                                    <Link to="/faq">
                                        FAQ's
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/privacy-policy">
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/term-condition">
                                        Terms & Condition
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/team">
                                        Team
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact">
                                        Contact Us
                                    </Link>
                                </li> */}
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="single-footer-widget">
                                <div className="free-trial-content">

                                    <h3>Subscribe</h3>
                                    <form className="newsletter-form">
                                        <input type="email" className="input-newsletter" placeholder="Enter your email here" />
                                        <button type="submit"><Icon.ArrowRight /></button>
                                    </form>
                                </div>
                                {/* <h3>Address</h3>
                            
                            <ul className="footer-contact-info">
                                <li> 
                                    <Icon.MapPin />
                                    27 Division St, New York, <br /> NY 10002, USA
                                </li>
                                <li>
                                    <Icon.Mail />
                                    Email: <Link to="mailto:spet@gmail.com">spet@gmail.com</Link>
                                </li>
                                <li> 
                                    <Icon.PhoneCall />
                                    Phone: <Link to="tel:321984754">+ (321) 984 754</Link>
                                </li>
                            </ul> */}
                                <br></br>

                                <ul className="social-links">
                                    <li>
                                        <a href="https://www.facebook.com/getwidget/" className="facebook" target="_blank" rel="noreferrer"><Icon.Facebook /></a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/getwidgetdev" className="twitter" target="_blank" rel="noreferrer"><Icon.Twitter /></a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/getwidgetdev/" className="instagram" target="_blank" rel="noreferrer"><Icon.Instagram /></a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/showcase/getwidget/" className="linkedin" target="_blank" rel="noreferrer"><Icon.Linkedin /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>


                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="copyright-area">
                                <p>Copyright &copy; {currentYear} All rights reserved by <a href="https://getwidget.dev/" target="_blank">GetWidget</a></p>
                            </div>
                        </div>
                        {/* <div className="col-lg-6 col-md-6">
                    <div className="single-footer-widget">

                    <ul className="social-links">
                                <li>
                                    <a href="https://www.facebook.com/" className="facebook" target="_blank" rel="noreferrer"><Icon.Facebook /></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" className="twitter" target="_blank" rel="noreferrer"><Icon.Twitter /></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/" className="instagram" target="_blank" rel="noreferrer"><Icon.Instagram /></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/" className="linkedin" target="_blank" rel="noreferrer"><Icon.Linkedin /></a>
                                </li>
                            </ul>
                            </div>
                    </div> */}
                    </div>
                </div>

                <img src="https://ik.imagekit.io/ionicfirebaseapp/map_4W_fMKXrz.png" loading="lazy" className="map" alt="map" />

                {/* Shape Images */}
                {/* <div className="shape1">
                    <img src={Shape1} alt="shape" />
                </div>
                <div className="shape8 rotateme">
                    <img src={Shape2} alt="shape" />
                </div> */}
            </footer>
        );
    }}>
    </StaticQuery>
);

export default Footer;