import React from "react";
import Layout from "../components/_App/layout";
import MainBanner from '../components/MachineLearningTwo/MainBanner';
import OurServices from '../components/MachineLearningTwo/OurServices';
import FunFactsArea from '../components/Common/FunFactsArea';
import BlogCard from '../components/MachineLearningTwo/BlogCard';
import Footer from "../components/_App/Footer";
import NavbarStyleThree from "../components/_App/NavbarStyleThree";
import { graphql, StaticQuery } from "gatsby";
const GET_HOME_PAGE_STATIC_CONTENT = graphql`
query GetHomePageStaticContent {
    strapiHomePageSeo {
    blog_section_description
    blog_section_title
    contact_us_block_description
    contact_us_block_title
    email_subscribers
    home_page_banner_description
    home_page_banner_title
    premium_resources
    product_block_description
    product_block_title
    total_downloads
}
}
`
const IndexPage = () => (
    <StaticQuery query={GET_HOME_PAGE_STATIC_CONTENT} render={data => {
        const homePageData = data.strapiHomePageSeo;
        return (
            <Layout>
                <NavbarStyleThree />
                <MainBanner title={homePageData.home_page_banner_title} description={homePageData.home_page_banner_description} />
                <OurServices title={homePageData.product_block_title} description={homePageData.product_block_description} />
                <FunFactsArea title={homePageData.contact_us_block_title} description={homePageData.contact_us_block_description} totalDownloads={homePageData.total_downloads} premiumResources={homePageData.premium_resources} emailSubscribers={homePageData.email_subscribers} />
                <BlogCard title={homePageData.blog_section_title} description={homePageData.blog_section_description} />
                <Footer />
            </Layout>
        )
    }}>

    </StaticQuery>
)

export default IndexPage;
